(function ($) {
  'use strict';

  // ------------------------------------------------
  // Utility Functions

  var storeLocationByIp = function () {
    site.geo.locate(function (args) {
      $(window).trigger('geolocation_callback', args);
    });
    // unable to check for error "Not now" in Firefox: https://bugzilla.mozilla.org/show_bug.cgi?id=675533
    // instead, ignore geolocation regardless, showing it only once per session
    sessionStorage.setItem('IGNORE_GEO', '1');
  };
  var getStoreFromLatLongCoords = function (args) {
    var ipargs = args || site.geo.currentLocationData;
    var params = {
      fields:
        'DOOR_ID, ' +
        'DOORNAME, ' +
        'EVENT_NAME, ' +
        'SUB_HEADING, ' +
        'EVENT_START_DATE, ' +
        'EVENT_END_DATE, ' +
        'EVENT_IMAGE, ' +
        'EVENT_FEATURES, ' +
        'EVENT_TIMES, ' +
        'SERVICES, ' +
        'STORE_HOURS, ' +
        'ADDRESS, ' +
        'ADDRESS2, ' +
        'STATE_OR_PROVINCE, ' +
        'CITY, ' +
        'REGION, ' +
        'COUNTRY, ' +
        'ZIP_OR_POSTAL, ' +
        'PHONE1, ' +
        'STORE_TYPE, ' +
        'LONGITUDE, ' +
        'LATITUDE',
      radius: '50',
      latitude: ipargs.lat || ipargs.latitude, // '37.778824',
      longitude: ipargs.lng || ipargs.longitude, // '-122.190887',
      results_ordered: 1
    };

    // make RPC to backend with lat/lng coords to try and get some stores near our user
    /* global generic */
    generic.jsonrpc.fetch({
      method: 'locator.doorsandevents',
      params: [params],
      onSuccess: function (result) {
        var results = result.getValue();

        // we found a store
        // if we dont we will show default drupal content
        if (!results.error) {
          var storeData = results.results_ordered;

          renderStoreData(storeData);
        }
      },
      onFailure: function (result) {
        // console.log(result.getMessages());
      }
    });
  };
  var renderLocalEvents = function () {
    var checked_events = [];

    for (var i = 0, len = sessionStorage.EVENT_COUNT; i < len; i++) {
      // skip if we already found this event
      if (_.indexOf(checked_events, sessionStorage['EVENT_NAME_' + i]) !== -1) {
        continue;
      }
      var event = {};

      event.index = i + 1;
      var event_start = new Date(sessionStorage['EVENT_START_DATE_' + i].replace(/-/g, ' '));
      var event_end = new Date(sessionStorage['EVENT_END_DATE_' + i].replace(/-/g, ' '));
      var dayNames =
        $('[data-local_days_of_week]').size() > 0
          ? $('[data-local_days_of_week]')
              .attr('data-local_days_of_week')
              .replace(/"/g, '')
              .split(',')
          : [];
      var dayOfWeek = _.startsWith(
        sessionStorage['EVENT_START_DATE_' + i],
        dayNames[event_start.getDay()]
      )
        ? ''
        : dayNames[event_start.getDay()] + ' ';

      event.EVENT_START_DATE = dayOfWeek + event_start.toLocaleDateString(); // sessionStorage['EVENT_START_DATE_'+i].replace(/00:00:00/g, '');
      event.EVENT_END_DATE = event_end.toLocaleDateString(); // sessionStorage['EVENT_END_DATE_'+i].replace(/00:00:00/g, '');
      event.EVENT_NAME = sessionStorage['EVENT_NAME_' + i];
      event.EVENT_FEATURES = sessionStorage['EVENT_FEATURES_' + i];
      event.EVENT_TIMES = sessionStorage['EVENT_TIMES_' + i];
      event.EVENT_IMAGE = sessionStorage['EVENT_IMAGE_' + i];
      event.DOORNAME = sessionStorage['EVENT_DOORNAME_' + i];
      event.DOOR_ID = sessionStorage['EVENT_DOOR_ID_' + i];
      event.ADDRESS = sessionStorage['EVENT_ADDRESS_' + i];
      event.CITY = sessionStorage['EVENT_CITY_' + i];
      event.STATE_OR_PROVINCE = sessionStorage['EVENT_STATE_OR_PROVINCE_' + i];
      event.ZIP_OR_POSTAL = sessionStorage['EVENT_ZIP_OR_POSTAL_' + i];
      event.PHONE1 = sessionStorage['EVENT_PHONE_' + i];
      /* jshint -W083 */
      if ($('[path="/templates/locator/door_event.tmpl"]').size() > 0) {
        generic.template.get({
          path: '/templates/locator/door_event.tmpl',
          object: event,
          callback: function (html) {
            // append only if we have a match on the event name
            if ($(html).attr('data-store_event') === event.EVENT_NAME) {
              $('.events-near-you-container').append($(html));
            }
            // append for collection event
            if (
              $(html).attr('data-store_event') === event.EVENT_NAME &&
              $('.collection-event[data-store_event]').attr('data-store_event') === event.EVENT_NAME
            ) {
              $('.collection-event').append($(html).find('.text'));
            }
          }
        });
      }
      checked_events.push(event.EVENT_NAME);
    }
    if ($('.events-near-you-container').html() !== '') {
      $('.events-near-you').removeClass('hidden');
    }
  };
  var updateStoreDataInFooter = function () {
    var door_data = sessionStorage.DOOR_DATA;
    var stores = $('.block--footer-stores');

    // hide default store data from drupal and show the store data we were able to set and retrieve
    stores.find('div.found_store_directions').removeClass('hidden');
    // do not hide per MAC-1066
    // stores.find('div.default_store_data').addClass('hidden');

    // set store data
    stores.find('p.store_info').html(door_data);

    // set directions link for the store
    stores
      .find('.found_store_directions a')
      .not('.book-appointment-link')
      .attr('target', '_blank')
      .attr(
        'href',
        'https://www.google.com/maps/dir//' +
          sessionStorage.DOOR_LATITUDE +
          ',' +
          sessionStorage.DOOR_LONGITUDE +
          '/'
      );
  };
  var renderStoreData = function (storeData) {
    var firstStore = storeData[0];
    var address = firstStore.ADDRESS;

    address += firstStore.ADDRESS2 ? '<br/>' + firstStore.ADDRESS2 : '';

    var renderedAddress =
      firstStore.DOORNAME +
      ' ' +
      firstStore.SUB_HEADING +
      '<br/>' +
      address +
      '<br/>' +
      firstStore.CITY +
      ', ' +
      firstStore.STATE_OR_PROVINCE +
      ' ' +
      firstStore.ZIP_OR_POSTAL +
      '<br/>' +
      firstStore.PHONE1;

    // set store data in session storage
    sessionStorage.DOOR_DATA = renderedAddress;
    sessionStorage.DOOR_LATITUDE = firstStore.LATITUDE;
    sessionStorage.DOOR_LONGITUDE = firstStore.LONGITUDE;
    sessionStorage.DOOR_ID = firstStore.DOOR_ID;
    sessionStorage.STORE_TYPE = firstStore.STORE_TYPE;
    sessionStorage.LOCAL_STORE_COUNT = 0;
    sessionStorage.EVENT_COUNT = 0;

    // local stores (capped at 2)
    var stores = storeData.slice(0, Math.min(2, storeData.length));

    sessionStorage.LOCAL_STORE_COUNT = stores.length;
    $(stores).each(function (i, store) {
      sessionStorage['DOORNAME_' + i] = store.DOORNAME;
      sessionStorage['LATITUDE_' + i] = store.LATITUDE;
      sessionStorage['LONGITUDE_' + i] = store.LONGITUDE;
      sessionStorage['SUB_HEADING_' + i] = store.SUB_HEADING;
      sessionStorage['DISTANCE_' + i] = store.DISTANCE;
      sessionStorage['DOOR_ID_' + i] = store.DOOR_ID;
      sessionStorage['ADDRESS_' + i] = store.ADDRESS;
      sessionStorage['CITY_' + i] = store.CITY;
      sessionStorage['STATE_OR_PROVINCE_' + i] = store.STATE_OR_PROVINCE;
      sessionStorage['ZIP_OR_POSTAL_' + i] = store.ZIP_OR_POSTAL;
      sessionStorage['PHONE_' + i] = store.PHONE1;
    });

    // store event data
    var events = $(storeData).filter(function (i, event) {
      if (
        event.hasOwnProperty('EVENT_START_DATE') &&
        event.hasOwnProperty('EVENT_END_DATE') &&
        event.hasOwnProperty('EVENT_NAME')
      ) {
        if (
          event.EVENT_START_DATE !== null &&
          event.EVENT_END_DATE !== null &&
          event.EVENT_NAME !== ''
        ) {
          return new Date(event.EVENT_END_DATE.replace(/-/g, ' ') > new Date());
        }

        return false;
      }
    });

    if (events.length > 0) {
      sessionStorage.EVENT_COUNT = events.length;
      $(events).each(function (i, event) {
        sessionStorage['EVENT_NAME_' + i] = event.EVENT_NAME;
        sessionStorage['EVENT_FEATURES_' + i] = event.EVENT_FEATURES;
        sessionStorage['EVENT_START_DATE_' + i] = event.EVENT_START_DATE;
        sessionStorage['EVENT_END_DATE_' + i] = event.EVENT_END_DATE;
        sessionStorage['EVENT_TIMES_' + i] = event.EVENT_TIMES;
        sessionStorage['EVENT_IMAGE_' + i] = event.EVENT_IMAGE;
        sessionStorage['EVENT_DOORNAME_' + i] = event.DOORNAME;
        sessionStorage['EVENT_DOOR_ID_' + i] = event.DOOR_ID;
        sessionStorage['EVENT_ADDRESS_' + i] = event.ADDRESS;
        sessionStorage['EVENT_CITY_' + i] = event.CITY;
        sessionStorage['EVENT_STATE_OR_PROVINCE_' + i] = event.STATE_OR_PROVINCE;
        sessionStorage['EVENT_ZIP_OR_POSTAL_' + i] = event.ZIP_OR_POSTAL;
        sessionStorage['EVENT_PHONE_' + i] = event.PHONE1;
      });
    }

    updateStoreDataInFooter();
    $(window).trigger('local_store_data');
  };

  // ------------------------------------------------
  // Event Handers

  $(window).on('window::renderStoreData', function (e, storeData) {
    renderStoreData(storeData);
  });

  $(window).on('window::renderStoreDataFromFav', function (e, storeData) {
    getStoreFromLatLongCoords({
      lat: storeData[0].LATITUDE,
      lng: storeData[0].LONGITUDE
    });
  });

  $(window).on('gnavdata_success', function (e, results) {
    // Option to disable the entire store rendering if the checkbox is checked in the cms
    var disabled = $('.footer-stores-v1').first().hasClass('data-lookup-disabled');

    if (disabled) {
      return;
    }

    // store door data so it can be updated in the footer
    if (!_.isNull(results) && !_.isNull(results.DOOR_ID)) {
      sessionStorage.DOOR_ID = results.DOOR_ID;
      sessionStorage.DOOR_DATA = results.RENDERED_DOOR_DATA;
      sessionStorage.DOOR_LATITUDE = results.DOOR_DATA_LATITUDE;
      sessionStorage.DOOR_LONGITUDE = results.DOOR_DATA_LONGITUDE;
    }

    var door_data = sessionStorage.DOOR_DATA;
    var door_name_test = sessionStorage.DOORNAME_0;

    // do we have door data already?
    if (door_name_test && door_name_test != 'null') {
      sessionStorage.STORE_TYPE = door_data.STORE_TYPE;
      // this triggers local stores and events for stores landing, account landing, and collections containing events
      $(window).trigger('local_store_data');
      updateStoreDataInFooter();
    } else if (
      !!results &&
      !_.isNull(results.DOOR_DATA_LATITUDE) &&
      !_.isNull(results.DOOR_DATA_LONGITUDE)
    ) {
      // do we at least have lat/long from gnavdata_success response?
      getStoreFromLatLongCoords({
        lat: results.DOOR_DATA_LATITUDE,
        lng: results.DOOR_DATA_LONGITUDE
      });
    } else {
      // default: get store data from geolocation
      if (sessionStorage.getItem('IGNORE_GEO') === null) {
        storeLocationByIp();
      }
    }
  });

  // Listener for the callback from the geolocation code
  $(window).on('geolocation_callback', function (e, args) {
    getStoreFromLatLongCoords(args);
  });

  $(window).on('local_store_data', function (e) {
    renderLocalEvents();
  });
})(jQuery);
